import React, {useState,useEffect} from 'react';
import Layout from '../components/layout'
import StyledBackgroundSection from '../components/backgroundimage'

import AvailableUnitsSection from '../components/available-units-section';

import ImageGallery from '../components/image-gallery';

import ContactFormModal from '../components/contact-form-modal'
import { graphql, useStaticQuery } from 'gatsby';

import { Helmet } from "react-helmet";
import colors from '../components/theme';
import BuildingImages from '../components/building-images';
import NeighborhoodMap from '../components/neighborhoodmap';
import VideoPlayer from '../components/videoplayer';


import styled from 'styled-components';
import AlertBanner from '../components/alertbanner';
import MatterportContainer from '../components/matterport-container';
import RoomConfiguration from '../components/roomconfiguration';


const HeroSection = styled.section`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 100vw;
  font-family: rubik;
 
`

const HeroHeading = styled.h1`
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-size: 4em;
  text-align: center;
  font-family: rubik;
  @media (max-width: 768px) {
    font-size: 2em;
  } 
`

const HeroSubheading = styled.h3`
  color: white;
  font-weight: 400;
  
  font-size: 2em;
  @media (max-width: 850px){
    text-align: center; 
  }
  @media (max-width: 768px) {
    font-size: 1em;
    width: 75%;
    text-align: center`

const VirtualTourSection = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 50px 0px 50px 0px;
flex-direction: column;
position: relative;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23d4f4ef' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

`

const VirtualTourTitle = styled.div`
    font-family: rubik;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    font-size: 30px;
    padding: 20px 0px 20px 0px;
    
   
    @media(max-width: 1440px){
        font-weight: 700;
        font-family: rubik;
        text-align: center;
        position: relative;
        font-size: 20px;
        top: 0;
        left: 0;
        text-align: center;
        margin: 1em 0 0.25em 0;
    }
`

const VirtualTourContainer = styled.div`
display: flex;
overflow: hidden;
position: relative;
background-color: ${colors.gray};
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 5px;
box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);

`



const TwentySixTwentyFourMLK = () => {

    //query all 18th and linden photo srcs
    const imgData = useStaticQuery(graphql`
    query MLKImages {
      allContentfulUnit(filter: {
        property: { 
          propertyName: {
            eq: "27th & Northgate" 
          }
        }
      }) {
        edges {
          node {
            floor
            id
            size
            baseRent
            unitNumber
            vaultedCeilings
            patio
            balcony
            updatedAt
            dateAvailable
            unitLayout {
              fluid(quality:100){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            unitThumbnail {
              fixed(width: 400, height: 400, quality: 100) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
            property {
              propertyName
            }
            unitPhotos {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      allContentfulProperty(filter: {propertyName: {eq: "27th & Northgate"}}) {
        edges {
          node {
            commonAreaImages {
              fluid(quality: 50, maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            heroImage {
              fluid(quality: 100, maxWidth: 2000){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            exteriorPhotos {
              fluid(quality: 100, maxWidth: 2000){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            roomConfigurations {
              fluid(quality: 50, maxWidth: 1000){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            neighborhoodMap {
              fluid(quality: 100, maxWidth: 1000){
                ...GatsbyContentfulFluid_withWebp
              }
            }

            
          }
        }
      }
    }
      `)


    


    const [galleryModalOpen, setGalleryModalOpen] = useState(false);
    
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [selectedUnit, setSelectedUnit] = useState("");
    const [filteredImages, setFilteredImages] = useState([]);
    const [selectedUnitDetails, setSelectedUnitDetails] = useState(
        {building: 'unknown',
    sqft: 'unknown',
 });





 

    return (
      <>
        <Helmet>
          <title>Square One - 27th &amp; Northgate</title>
          <meta name="title" content="Square One - 27th &amp; Northgate" />
          <meta name="description" content="Comprised of a series of townhouse communities, each with private studios and a spacious, central lounge and kitchen, The Northgate will have you immediately feeling at home, immersed in thoughtful design that is equal parts functional and inspiring."/>
          <link rel="canonical" href="http://squareone-living.com/27th-and-Northgate" />
            
        
          </Helmet>
        <Layout>
          
            <ImageGallery concessionLength={3} setContactModalOpen={setContactModalOpen} selectedUnitDetails={selectedUnitDetails} setFilteredImages={setFilteredImages} filteredImages={filteredImages} setIsOpen={setGalleryModalOpen} isOpen={galleryModalOpen} unit={selectedUnit} imgData={imgData}/>
            <ContactFormModal propertyName={"The Northgate"} setIsOpen={setContactModalOpen} isOpen={contactModalOpen} unit={selectedUnit} allUnits={imgData.allContentfulUnit.edges}/>
              <StyledBackgroundSection fluid={ imgData.allContentfulProperty.edges[0].node.heroImage.fluid || null }>
                <HeroSection>
                  <HeroHeading>27th &amp; Northgate</HeroHeading>
                </HeroSection>
                  
              </StyledBackgroundSection >

           
            
            <AvailableUnitsSection  concessionLength={3} selectedUnit= {selectedUnit} setSelectedUnitDetails={setSelectedUnitDetails} setSelectedUnit={setSelectedUnit} setGalleryModalOpen={setGalleryModalOpen} imgData={imgData} setContactModalOpen={setContactModalOpen}>
                
            </AvailableUnitsSection>
            {/* <BuildingSection /> */}
            <VideoPlayer url="https://vimeo.com/468242797"/>
            <RoomConfiguration images={imgData && imgData.allContentfulProperty.edges[0].node.roomConfigurations}/>

            <NeighborhoodMap 
            image={imgData.allContentfulProperty.edges[0].node.neighborhoodMap}
            neighborhoodBlurb="Built new this year, The Northgate sits at the epicenter of Oakland’s vibrant arts, music, and culture community.
            Nestled in a quiet neighborhood just six blocks from the iconic Fox Theatre and the legendary nightlife of Broadway, 
           the Linden provides the ideal location for a walkable commute during the week, whether you’re headed to downtown Oakland 
           or to San Francisco via BART, and convenient access to everything downtown Oakland has to offer."  />

            {/* <BuildingImages images={imgData && imgData.allContentfulProperty.edges[0].node.exteriorPhotos}/> */}
            {/* <VirtualTourSection>
              <VirtualTourContainer>
                <VirtualTourTitle>Check out the Space in 3D!</VirtualTourTitle>
                <MatterportContainer tourId={"beDvxH3pas2"}/>
              </VirtualTourContainer>
            </VirtualTourSection> */}
            
                
            {/* <GalleryGrid photos = {imgData.allContentfulProperty.edges[0].node.commonAreaImages} setIsLightBoxOpen={setIsLightBoxOpen} setPhotoIndex={setPhotoIndex}/>
            {isLightBoxOpen && <LightBox 
            images={imgData.allContentfulProperty.edges[0].node.commonAreaImages}
            photoIndex={photoIndex}
            isOpen={isLightBoxOpen}
            setPhotoIndex={setPhotoIndex}
            setIsOpen={setIsLightBoxOpen}
            
            /> */}
            
            {/* <Amenities /> */}
            
        </Layout>
        </>
        
    )
}


export default TwentySixTwentyFourMLK;